import './App.css';
import { Helmet } from 'react-helmet';


function App() {
  return (
    <>
    <Helmet>
    <title>Shital AI - Technology Driven Company in Ahmedabad</title>
    <meta name="description" content="Shital AI is a leading technology-driven company in Ahmedabad, offering innovative AI solutions and services to businesses across various industries." />
    <meta name="keywords" content="Shital AI, Technology Company Ahmedabad, AI Solutions, Artificial Intelligence Ahmedabad, Technology Services, Innovative AI, AI Services, Ahmedabad Tech Company" />
    <meta name="robots" content="index, follow" />
    <meta property="og:title" content="Shital AI - Technology Driven Company in Ahmedabad" />
    <meta property="og:description" content="Shital AI is a leading technology-driven company in Ahmedabad, offering innovative AI solutions and services to businesses across various industries." />
    <meta property="og:url" content="https://www.shitalai.com" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shitalai.com/logo.png" />
  </Helmet>
    <div className="App">
  <div className="relative bg-white">
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="px-6 pt-10 pb-24 sm:pb-32 lg:col-span-7 lg:px-0 lg:pt-48 lg:pb-56 xl:col-span-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <img
              className="h-11"
              src="/logo.png"
              alt="Shital AI"
            />
            
            <h1 className="mt-24 text-3xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-6xl">
            Under Construction //  Coming Soon!


            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
            We are currently working on something amazing. Please stay tuned for our launch!

In the meantime, if you have any questions or need assistance, feel free to contact us:


            </p>
            <a href='Tel:+919727555515'>

            <div className="mt-10 flex items-center gap-x-6">
              <div
                className="rounded-md  px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm  focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style={{backgroundColor:"rgb(0, 74, 147)"}}>
                  
                 <svg class="h-8 w-8 text-white-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
                 
                </svg>
                
              </div>
            
            +91 9727555515
            </div>
            </a>
            <a href='mailto:info@shitalai.com'>
            <div className="mt-10 flex items-center gap-x-6">
            
              <div
                className="rounded-md  px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm  focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style={{backgroundColor:"rgb(0, 74, 147)"}}>
                  
                  <svg class="h-8 w-8 text-white-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" />           
                </svg>
                
              </div>
              info@shitalai.com
               
            </div>
            </a>
            <a href='https://www.google.com/maps/place/Shital+AI/@23.0365624,72.5268436,15z/data=!4m6!3m5!1s0x395e85204629f56f:0x34dd48c03a84c31c!8m2!3d23.0365624!4d72.5268436!16s%2Fg%2F11ldx9drlg?entry=ttu'>
            <div className="mt-10 flex items-center gap-x-6">
            
              <div
                className="rounded-md  px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm  focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style={{backgroundColor:"rgb(0, 74, 147)"}}>
                  
                  <svg class="h-8 w-8 text-white-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>

                
              </div>
              9/C Nehru Park Opp Sardar Center Lad Society Road Vastrapur Ahmedabad-380015
               
            </div>
            </a>
          </div>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
          <img
            className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
            src="/cms.png"
            alt=""
          />
        </div>
      </div>
    </div>
    </div>
    </>
  );
}

export default App;
